@use "../abstracts" as *;
@use "../components/card";
@use "../components/splide";
@import "../../variables";

.banner {
    position: relative;
    overflow: hidden;

    &-splide {
        li {
            @include flex(flex, column, center, center);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__name {
            font-size: 30px;
            color: $white;
            width: 90%;

            @include for-desktop-up {
                font-size: 50px;
            }
        }

        &__content {
            position: absolute;
            top: 50%;
            left: 45%;
            z-index: 10;
            @include flex(flex, column, flex-start, center);
            width: 75%;
            height: 100%;
            padding: 1rem 0.5rem;
            max-width: 600px;
            transform: translate(-50%, -50%);

            @include for-desktop-up {
                width: 50%;
            }

            h2 {
                span {
                    font-size: 36px;
                    font-weight: 900;

                    strong {
                        font-size: 36px;
                        font-weight: 900;
                    }
                }
            }

            p {
                span {
                    font-size: 20px;
                    line-height: 26px;
                }

                font-size: 20px;
                line-height: 26px;

                &:last-of-type {
                    margin-bottom: 1.5rem;
                }

                // @include for-phone-only {
                //     display: none;
                // }
            }

            @include for-desktop-up {
                height: auto;
                background-color: transparent;
                left: 15em;
                transform: translateX(5%);
                transform: translateY(-50%);
            }

            @include for-tablet-portrait-up {
                p {
                    font-size: size(text, 400);
                }
            }

            @include for-phone-only {

                h2 {
                    line-height: 30px;
                    span {
                        font-size: 28px;
                        font-weight: 900;

                        strong {
                            font-size: 28px;
                            font-weight: 900;
                        }
                    }
                }

                p {
                    font-size: 16px;
                }
            }
        }

        &__button {
            min-width: 207px;
            height: 49px;
            background-color: #C00A11;
            color: $white;

            &:hover {
                color: $white !important;
                background-color: #C00A11 !important;
            }
        }

    }

    &-filter {
        &__image {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: #5A172F6B;
            z-index: 8;
        }
    }

    .splide {
        &__arrow {
            &--next {
                right: 6rem;
            }

            &--prev {
                left: 6rem;
            }

            svg {
                width: 29px;
                height: 46px;
                fill: white;
            }
        }

        &__pagination {
            bottom: 1.5rem;

            &__page {
                background-color: white;

                &:not(.is-active) {
                    background-color: transparent;
                    border: 1px solid white;
                }
            }
        }
    }

    &-information {
        background-color: #ED1B24;
        justify-content: center;
        height: auto;
        padding: 10px 5px 10px 5px;
        margin-top: -2px;

        @include for-desktop-up {
            height: 90px;
            padding: 0px;
        }

        &__list {
            max-width: 1200px;
            justify-content: center;
        }

        &__item {
            padding: 0px;
            margin-right: 8px;
            font-family: Lato;
            font-size: 14px;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            display: flex;
            margin-bottom: 10px;
            max-width: fit-content;

            &:last-of-type {
                margin-bottom: 0px;
            }

            img {
                width: 28px;
                height: auto;
                object-fit: contain;
                margin-right: 5px;
            }

            span,
            i {
                color: white;
                display: flex;
                align-items: center;
            }

            span {
                width: 75%;
                max-width: 130px;
            }

            i {
                font-size: 31px;
                margin-right: 5px;
            }

            @include for-desktop-up {
                margin-bottom: 0px;
                font-size: 14px;
                margin-right: 17px;

                img {
                    width: 36px;
                    height: auto;
                    object-fit: contain;
                    margin-right: 10px;
                }

                span,
                i {
                    color: white;
                    display: flex;
                    align-items: center;
                }

                span {
                    width: 75%;
                    max-width: 130px;
                }

                i {
                    font-size: 41px;
                    margin-right: 10px;
                }
            }

            @include for-phone-only {
                &:nth-child(2):nth-child(3) {
                    display: none;
                }
            }
        }
    }

    @include for-tablet-portrait-only {
        .splide__arrow {
            top: 60%;
        }
    }
}


.middleBanner {
    position: relative;
    overflow: hidden;

    &__item {

        img {
            width: 100%;
            height: auto;
            min-height: 305px;
            object-fit: cover;
        }
    }


    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-40%, -50%);
        max-width: 408px;

        p {
            margin-top: 28px;
            font-family: Lato;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #727272;

            @include for-phone-only {
                color: #555555;
            }
        }
    }

    &__name {
        font-family: Lato;
        font-size: 40px;
        font-weight: 800;
        line-height: 48px;
        letter-spacing: 0em;
        color: #000000;

        &::after {
            content: "";
            display: block;
            width: 48px;
            height: 3px;
            background-color: #ED1B24;
            margin-right: auto;
            margin-top: 4px;
        }
    }

    &__button {
        min-width: 207px;
        height: 49px;
        margin-top: 26px;
        background-color: #C00A11;
        color: $white;

        &:hover {
            color: $white !important;
            background-color: #C00A11 !important;
        }
    }
}

.exams {
    background-color: #FAFAFA;

    &__title {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
            font-family: Lato;
            font-size: 36px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: 0em;
            text-align: center;
            color: #000000;
            margin-bottom: 4;
        }

        span {
            font-family: Roboto;
            font-size: 18px;
            font-weight: 300;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: center;
            display: block;
            color: #555555;
            margin-bottom: 4;
        }

        a {
            font-family: Roboto;
            font-size: 15px;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: center;
            text-decoration: underline;
            color: #FF0110;
        }
    }

    &-card {
        display: flex;
        padding: 0px 15px 15px 15px;
        flex-direction: column;
        box-shadow: 6px 6px 15px 0px #0000001F;
        background-color: white;
        border-radius: 11px;
        height: 267px;

        @include for-phone-only {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }

        &__image {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
        }

        &__figure {
            margin-top: 31px;
            padding: 16px;
            border-radius: 50%;
            border: 1px solid #ED1B24;
            width: fit-content;
            height: fit-content;

            img {
                width: 59px;
                height: auto;

            }
        }

        &__title {
            font-family: Lato;
            font-size: 18px;
            font-weight: 800;
            line-height: 24px;
            letter-spacing: 0.005em;
            text-align: center;
            color: #000000;
            margin-bottom: 6px;

        }

        &__text {
            position: relative;

            p {
                font-family: Lato;
                font-size: 14px;
                font-weight: 300;
                line-height: 21px;
                letter-spacing: 0.005em;
                text-align: center;
                color: #555555;
            }
        }

    }

    &-splide {
        .splide__arrow {
            border-radius: 50%;
            display: flex;
            padding: 3px;
            justify-content: center;
            border: 3px solid #C9C9C9;

            @include for-desktop-up {
                &--prev {
                    left: -5rem;
                }

                &--next {
                    right: -5rem;
                }
            }

            @include for-phone-only {
                &--prev {
                    left: 0.5rem;
                }

                &--next {
                    right: 0.5rem;
                }

            }

            svg {
                fill: #C9C9C9;

                @include for-desktop-up {
                    &--prev {
                        left: -5rem;
                    }

                    &--next {
                        right: -5rem;
                    }
                }

                font-size: 30px;
            }
        }

        .splide__track {
            height: 280px;
        }
    }
}

.about {

    &-title {
        font-family: Lato;
        font-size: 36px;
        font-weight: 800;
        line-height: 48px;
        letter-spacing: 0em;
        color: #000000;


        &::after {
            content: "";
            display: block;
            width: 48px;
            height: 3px;
            margin-top: 0.5rem;
            background-color: #ED1B24;
        }

    }

    &-link {
        min-width: 207px;
        height: 49px;
        background-color: #FF0110;
        color: $white;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;


        &:hover {
            color: $white !important;
            background-color: #FF0110 !important;
        }

    }

    &-description {

        p {
            font-family: Lato;
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: left;
            color: #727272;

            strong {
                font-weight: 600;
                color: #000000;
            }
        }
    }

}

.middle {
    margin: 4rem 0;
    background-repeat: no-repeat;
    background-size: cover;

    @include for-phone-only {
        background-color: #e7e7e9;
        background-position: 25%;
    }


    @include for-phone-only {
        flex-direction: column;
    }

    &__column {
        background-repeat: no-repeat;
        background-position: 17% 0;
        margin-left: 40%;
    }

    &__title {
        color: #145993;
        font-weight: bold;

        @include for-phone-only {
            font-size: size(title, 100);
        }
    }

    &__text {
        font-size: 15px;
        color: #145993;
    }

    &__link {
        width: 244px;
    }

    &__image {
        position: absolute;
        left: 66%;

        @include for-phone-only {
            position: unset;
        }
    }


    &__button {
        width: 150px;
        height: 18px;
        background-color: #C00A11;
        color: $white;
        font-size: 15px;

        &:hover {
            color: $white !important;
            background-color: #C00A11 !important;
        }
    }

    @include for-desktop-up {

        &__button {
            width: 227px;
        }
    }

}

.covenants {
    &__title {
        font-family: Lato;
        font-size: 28px;
        font-weight: 800;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
        width: fit-content;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 4px;

        &::after {
            content: "";
            display: block;
            height: 3px;
            width: 50%;
            margin-right: auto;
            margin-left: auto;
            background-color: #ED1B24;
            margin-bottom: 14px;
        }
    }

    &__link {
        font-family: Roboto;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #727272;

    }

    &__image {
        @extend %transitionDefault;
    }

    .splide {
        &__arrow {
            background: transparent;
            opacity: 1;

            svg {
                fill: #A7A7AC;
                width: 28px;
                height: 46px;
            }
        }
    }
}

.faqs {
    background-color: #FAFAFA;

    &__container {
        margin-left: auto;
        margin-right: auto;

        @include for-desktop-up {
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    &__image {
        display: none;
        padding: 32px;
        align-items: center;

        @include for-tablet-portrait-up {
            display: flex;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__content {

        background-color: #FFFFFF;
        border-radius: 30px;
        box-shadow: 2px 2px 4px 1px #0000001F;
        padding: 38px 20px 40px 20px;

        h2 {
            font-family: Lato;
            font-size: 28px;
            font-weight: 800;
            line-height: 48px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
            width: fit-content;


            &::after {
                content: "";
                display: block;
                width: 15%;
                height: 3px;
                margin-top: 4px;
                background-color: #ED1B24;
            }
        }

        @include for-desktop-up {
            h2 {
                font-size: 32px;
            }
        }
    }

    &-item {
        width: 100%;

        &__question {
            padding: 16px 0px 16px 0px;
            border-top: 1px solid #CDD6DA40;
            border-bottom: 1px solid #CDD6DA40;
            display: flex;

            i {
                margin-left: auto;
                width: fit-content;
                color: #000000;
                font-size: 16px;
            }
        }

        &__title {
            font-family: Inter;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: left;
            color: #000000;
            margin-bottom: 0px;
            max-width: 95%;

        }

        &__answer {
            p {
                padding: 16px 24px 16px 24px;
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0px;
                text-align: left;
                color: #3C3C43D9;

            }

            &--show {
                transform: scaleY(1);
            }
        }
    }

}
